import React from "react"
import { Link } from 'gatsby';
import Img from "gatsby-image";

const ProjectCard = ({node}) => 
    (
        <div className="project-card p-4 mx-auto relative w-full sm:w-1/2 lg:w-1/3 xl:w-1/4">
            <div className="project-card mb-24" style={{height:"331px"}}>
                    <Link to={node.slug && `/our-work/${node.slug}`}>
                        <Img className="object-cover object-center w-full h-full block" alt={node.description.childMarkdownRemark.excerpt}  alt={node.picture.title} fluid={node.picture.fluid} />
                        <div className="p-4 border-shadow">
                            <h3 className="text-gray-800 font-aktiv font-bold">{node.name}</h3>
                            {node.location && <h4 className="text-sm text-gray-800">{node.location}</h4>}
                        </div>
                    </Link>
            </div>

            <div className="bg-white h-50 absolute bottom-0 right-0 w-full p-4 opacity-0 project-peak mb-6">
                <Link to={node.slug && `/our-work/${node.slug}`}>
                    <h3 className="text-gray-800 font-aktiv font-bold">{node.name}</h3>
                    {node.location && <h4 className="text-sm text-gray-800 mb-4">{node.location}</h4>}
                    {node.client && <p className="text-sm soft-gray mb-2 pb-1 border-b">Client: {node.client}</p>}
                    {node.consultant && <p className="text-sm soft-gray mb-2 pb-1 border-b">Consultant: {node.consultant}</p>}
                    {node.startDate && <p className="text-sm soft-gray mb-2 pb-1 border-b">Start Date: {node.startDate}</p>}
                    {node.builtUpArea && <p className="text-sm soft-gray mb-2 pb-1 border-b">Built Up Area: {node.builtUpArea}</p>}
                    {node.projectDuration && <p className="text-sm soft-gray mb-2">Project Duration: {node.projectDuration}</p>}
                </Link>
            </div>
        </div>
    )

export default ProjectCard;
